const { EmailClient } = require("@azure/communication-email");

const connectionString =
  "endpoint=https://ci-is-guest-comm.unitedstates.communication.azure.com/;accesskey=5ANZrCUZArTmz13VcLoct0EvtHaKtvj4zWFEfkMP0Hq8UJaO2jOFJQQJ99AKACULyCpafvdvAAAAAZCSEudT";
const emailClient = new EmailClient(connectionString);

const emailPatientAccounting = async (Subject, Message, From, To) => {
  const message = {
    senderAddress:
      "DoNotReply@753dcb1c-6a22-4b08-86ce-cc94b72c34f2.azurecomm.net",
    recipients: { to: [{ address: To }] },
    content: {
      subject: Subject,
      html: Message,
    },
  };
  const poller = await emailClient.beginSend(message);
  const response = await poller.pollUntilDone();
  return response;
};

export default function SendEmail(from, to, subject, message) {
  return emailPatientAccounting(subject, message, from, to);
}
